.section-pages-benefits {
    width: 100%;
    display: flex;
    padding: 30px;
    background-color: #F5F5F5;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
    margin-block-end: 60px;
}

.section-pages-benefits--background-green {
    background-color: rgba(210, 235, 226, 0.4);
    color: #00575F;
}

.section-pages-benefits--background-purple {
    background: rgba(178, 175, 239, 0.2)
}

.section-pages-benefits--full-width {
    max-width: none;
}

.section-pages-benefits .benefits-wrapper {
    gap: 105px;
    width: 100%;
    display: flex;
    max-width: 1086px;
    flex-direction: row;
    justify-content: space-between;
}

.section-pages-benefits .benefit-content {
    flex: auto;
    display: flex;
    flex-flow: row wrap;
    text-align: left;
}

.section-pages-benefits .benefit-header {
    flex: 47px 0 0;
}

.section-pages-benefits .benefit-annotation {
    flex: 1;
}

.section-pages-benefits .benefit-icon {
    width: 27px;
}

.section-pages-benefits .benefit-annotation .content {
    margin: 10px 0 0;
    line-height: 18.5px;
}

.section-pages-benefits .benefit-annotation .content p {
    margin: 0;
    font-weight: normal;
}

@media screen and (min-width: 768px) {
    .section-pages-benefits {
        padding: 40px 30px 50px 30px;
        margin-block-end: 80px;
    }

    .section-pages-benefits .benefit-content {
        flex: 1;
        flex-flow: column wrap;
        text-align: center;
    }

    .section-pages-benefits .benefit-header {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 15px;
    }

    .section-pages-benefits .benefit-icon {
        width: 41px;
        height: auto;
        max-height: 41px;
    }

    .section-pages-benefits .benefit-title {
        font-size: var(--font-size-h5);
        font-weight: bold;
        font-weight: 700;
    }

        .section-pages-benefits .benefit-annotation .content {
            font-size: var(--font-size-body1);
            line-height: 1.25;
        }
}

@media only screen and (max-width: 767px) {

    .section-pages-benefits .benefits-wrapper {
        flex-direction: column;
        gap: 30px;
    }

    .section-pages-benefits .benefit-title {
        font-size: var(--font-size-subtitle1);
    }


}